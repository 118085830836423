/**
 * @author jaeho.lee104 on 2023. 04. 06..
 */
import styled from "styled-components";
import {Colors} from "./Styles";
import CommonComponent from "./CommonComponent";

const HorizontalDivider = styled(CommonComponent)`
  width: ${({width}) => width || "100%"};
  height: ${({height}) => height || "1px"};
  background-color: ${({backgroundColor}) => backgroundColor || Colors.GRAY_4};
`;

HorizontalDivider.displayName = "HorizontalDivider";
export default HorizontalDivider;