/**
 * @author jaeho.lee104 on 2023. 04. 06..
 */
import styled from "styled-components";
import {Colors} from "./Styles";
import CommonComponent from "./CommonComponent";

const VerticalDivider = styled(CommonComponent)`
  width: ${({width}) => width || "1px"};
  height: ${({height}) => height || "14px"};
  background-color: ${({backgroundColor}) => backgroundColor || Colors.GRAY_800};
`;
export default VerticalDivider;