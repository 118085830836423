// noinspection PointlessBooleanExpressionJS

import React from "react";
import FlexBox from "../../promotion-thirdworks-web/src/styledcomponents/FlexBox";
import Image from "../../promotion-thirdworks-web/src/styledcomponents/Image";
import Text from "../../promotion-thirdworks-web/src/styledcomponents/Text";
import {Colors, Sizes} from "./styledcomponents/Styles";
import styled from "styled-components";
import HorizontalDivider from "../../promotion-thirdworks-web/src/styledcomponents/HorizontalDivider";
import VerticalDivider from "../../promotion-thirdworks-web/src/styledcomponents/VerticalDivider";
import {useTranslation} from "react-i18next";
import {LanguageUtils} from "./LanguageUtils";
import useTablet from "../../promotion-thirdworks-web/src/uses/UseTablet";

const PolicyText = styled(Text)`
  max-width: ${props => props.maxWidth};
  color: ${Colors.SUB_TXT};
  font-size: ${props => props.fontSize};
  line-height: ${props => props.lineHeight};
  font-weight: normal;
  cursor: pointer;
`

const ContentText = styled(Text)`
  color: ${Colors.SUB_TXT2};
  font-size: ${props => props.fontSize};
  line-height: ${props => props.lineHeight};
  font-weight: normal;
`

const LabelText = styled(Text)`
  color: ${Colors.SUB_TXT2};
  font-weight: 700;
  font-size: ${props => props.fontSize};
  line-height: ${props => props.lineHeight};
`

const CommonFooter = () => {

    const isTablet = useTablet()


    const {t} = useTranslation();
    const language = "en"

    {/*------------------ PC ------------------*/
    }
    return <FlexBox backgroundColor={Colors.GRAY_0}>
        {!isTablet && <FlexBox width={"100%"}
                               alignItems={"center"}
                               justifyContent={"center"}
                               paddingTop={"40px"}
                               paddingBottom={"40px"}>
            <FlexBox width={"100%"}
                     maxWidth={Sizes.CONTENT_WIDTH}
                     height={"100%"}
                     alignItems={"center"}>
                <FlexBox
                    width={"100%"}
                    height={"100%"}
                    flexDirection={"column"}
                    justifyContent={"center"}>
                    <Image
                        alt={"footer-logo"}
                        width={"194px"}
                        style={{
                            cursor: "pointer"
                        }}
                        src={require("../../promotion-thirdworks-web/src/assets/footer-logo.svg").default}/>
                    <Text
                        marginTop={"20px"}
                        fontSize={"12px"}
                        lineHeight={"12px"}
                        color={Colors.GRAY_400}>COPYRIGHT 2023 ⓒ THIRDWORKS ALL RIGHTS RESERVED </Text>
                </FlexBox>
                <FlexBox
                    height={"100%"}
                    width={"100%"}
                    minWidth={"700px"}
                    flexDirection={"column"}>
                    <FlexBox
                        alignItems={"center"}>
                        <PolicyText
                            lineHeight={isTablet ? '16px' : '18px'}
                            fontSize={isTablet ? '12px' : '14px'}
                            maxWidth={isTablet ? '180px' : '220px'}
                            onClick={() => {
                                window.open("https://www.thirdworks.co.kr/terms-of-service?lang=en")
                            }}>
                            Terms of Service
                        </PolicyText>
                        <PolicyText
                            marginLeft={"20px"}
                            lineHeight={isTablet ? '16px' : '18px'}
                            fontSize={isTablet ? '12px' : '14px'}
                            maxWidth={isTablet ? '180px' : '220px'}
                            onClick={() => {
                                window.open("https://www.thirdworks.co.kr/privacy-policy?lang=en")
                            }}>
                            Privacy Policy
                        </PolicyText>
                        <PolicyText
                            lineHeight={isTablet ? '16px' : '18px'}
                            fontSize={isTablet ? '12px' : '14px'}
                            maxWidth={isTablet ? '180px' : '220px'}
                            visible={LanguageUtils.isKorean(language)}
                            marginLeft={"20px"}
                            onClick={() => {
                                window.open("/copyright-policy")
                            }}>
                            {t('copyright_policy')}
                        </PolicyText>
                        <PolicyText
                            lineHeight={isTablet ? '16px' : '18px'}
                            fontSize={isTablet ? '12px' : '14px'}
                            maxWidth={isTablet ? '180px' : '220px'}
                            visible={LanguageUtils.isKorean(language)}
                            marginLeft={"20px"}
                            onClick={() => {
                                window.open("/email-policy")
                            }}>
                            {t('deny_unauthorized_collection_of_email_addresses')}
                        </PolicyText>
                    </FlexBox>
                    <HorizontalDivider
                        paddingTop={"1px"}
                        marginTop={"20px"}
                        backgroundColor={"#E3E3E3"}/>
                    <FlexBox
                        marginTop={"30px"}
                        alignItems={"center"}>
                        <LabelText
                            fontSize={isTablet ? '12px' : '14px'}
                            lineHeight={isTablet ? '16px' : '18px'}>
                            THIRD WORKS INC.
                        </LabelText>
                    </FlexBox>
                    <FlexBox
                        marginTop={"10px"}
                        alignItems={"center"}>
                        <LabelText
                            fontSize={isTablet ? '12px' : '14px'}
                            lineHeight={isTablet ? '16px' : '18px'}>
                            Representative
                        </LabelText>
                        <ContentText
                            lineHeight={isTablet ? '16px' : '18px'}
                            fontSize={isTablet ? '12px' : '14px'}
                            marginLeft={"6px"}>
                            Kim Minji
                        </ContentText>
                        <VerticalDivider backgroundColor={Colors.SUB_TXT2}
                                         height={"12px"}
                                         marginLeft={"10px"}
                                         marginRight={"10px"}/>
                        <LabelText
                            fontSize={isTablet ? '12px' : '14px'}
                            lineHeight={isTablet ? '16px' : '18px'}>
                            Email
                        </LabelText>
                        <ContentText
                            lineHeight={isTablet ? '16px' : '18px'}
                            fontSize={isTablet ? '12px' : '14px'}
                            marginLeft={"6px"}>
                            business@thirdworks.co.kr
                        </ContentText>
                    </FlexBox>
                    <FlexBox
                        visible={LanguageUtils.isKorean(language)}
                        marginTop={"10px"}
                        alignItems={"center"}>
                        <LabelText
                            fontSize={isTablet ? '12px' : '14px'}
                            lineHeight={isTablet ? '16px' : '18px'}>
                            Business registration number
                        </LabelText>
                        <ContentText
                            lineHeight={isTablet ? '16px' : '18px'}
                            fontSize={isTablet ? '12px' : '14px'}
                            marginLeft={"6px"}>
                            784-87-02728
                        </ContentText>
                        <VerticalDivider backgroundColor={Colors.SUB_TXT2}
                                         height={"12px"}
                                         marginLeft={"10px"}
                                         marginRight={"10px"}/>
                        <LabelText
                            fontSize={isTablet ? '12px' : '14px'}
                            lineHeight={isTablet ? '16px' : '18px'}>
                            E-Commerce Registration Number
                        </LabelText>
                        <ContentText
                            lineHeight={isTablet ? '16px' : '18px'}
                            fontSize={isTablet ? '12px' : '14px'}
                            marginLeft={"6px"}>
                            Je2023-Sungnam BundangA-0171 Ho
                        </ContentText>
                        <VerticalDivider backgroundColor={Colors.SUB_TXT2}
                                         height={"12px"}
                                         marginLeft={"10px"}
                                         marginRight={"10px"}/>
                        <LabelText
                            fontSize={isTablet ? '12px' : '14px'}
                            lineHeight={isTablet ? '16px' : '18px'}>
                            {t('cpo')}
                        </LabelText>
                        <ContentText
                            lineHeight={isTablet ? '16px' : '18px'}
                            fontSize={isTablet ? '12px' : '14px'}
                            marginLeft={"6px"}>
                            Kim Minji
                        </ContentText>
                    </FlexBox>
                    <FlexBox
                        visible={!LanguageUtils.isKorean(language)}
                        marginTop={"10px"}
                        alignItems={"center"}>
                        <LabelText
                            fontSize={isTablet ? '12px' : '14px'}
                            lineHeight={isTablet ? '16px' : '18px'}>
                            Business registration number
                        </LabelText>
                        <ContentText
                            lineHeight={isTablet ? '16px' : '18px'}
                            fontSize={isTablet ? '12px' : '14px'}
                            marginLeft={"6px"}>
                            784-87-02728
                        </ContentText>
                    </FlexBox>
                    <FlexBox
                        visible={!LanguageUtils.isKorean(language)}
                        marginTop={"10px"}
                        alignItems={"center"}>
                        <LabelText
                            fontSize={isTablet ? '12px' : '14px'}
                            lineHeight={isTablet ? '16px' : '18px'}>
                            E-Commerce Registration Number
                        </LabelText>
                        <ContentText
                            lineHeight={isTablet ? '16px' : '18px'}
                            fontSize={isTablet ? '12px' : '14px'}
                            marginLeft={"6px"}>
                            Je2023-Sungnam BundangA-0171 Ho
                        </ContentText>
                    </FlexBox>
                    <FlexBox
                        visible={!LanguageUtils.isKorean(language)}
                        marginTop={"10px"}
                        alignItems={"center"}>
                        <LabelText
                            fontSize={isTablet ? '12px' : '14px'}
                            lineHeight={isTablet ? '16px' : '18px'}>
                            {t('cpo')}
                        </LabelText>
                        <ContentText
                            lineHeight={isTablet ? '16px' : '18px'}
                            fontSize={isTablet ? '12px' : '14px'}
                            marginLeft={"6px"}>
                            Kim Minji
                        </ContentText>
                    </FlexBox>
                    <FlexBox
                        marginTop={"10px"}
                        alignItems={"center"}>
                    </FlexBox>
                    <FlexBox
                        visible={LanguageUtils.isKorean(language)}
                        marginTop={"10px"}
                        alignItems={"flex-start"}>
                        <LabelText
                            fontSize={isTablet ? '12px' : '14px'}
                            lineHeight={isTablet ? '16px' : '18px'}>
                            Address
                        </LabelText>
                        <ContentText
                            lineHeight={isTablet ? '16px' : '18px'}
                            fontSize={isTablet ? '12px' : '14px'}
                            marginLeft={"6px"}>
                            (13488) 20, Pangyo-ro 289beon-gil, Bundang-gu, Seongnam-si, Gyeonggi-do, Republic of Korea
                        </ContentText>
                        <VerticalDivider backgroundColor={Colors.SUB_TXT2}
                                         height={"12px"}
                                         marginLeft={"10px"}
                                         marginRight={"10px"}/>
                        <LabelText
                            fontSize={isTablet ? '12px' : '14px'}
                            lineHeight={isTablet ? '16px' : '18px'}>
                            Contact
                        </LabelText>
                        <ContentText
                            lineHeight={isTablet ? '16px' : '18px'}
                            fontSize={isTablet ? '12px' : '14px'}
                            marginLeft={"6px"}>
                            +82 10-2476-9700
                        </ContentText>
                    </FlexBox>
                    <FlexBox
                        visible={!LanguageUtils.isKorean(language)}
                        marginTop={"10px"}
                        alignItems={"flex-start"}>
                        <LabelText
                            fontSize={isTablet ? '12px' : '14px'}
                            lineHeight={isTablet ? '16px' : '18px'}>
                            Address
                        </LabelText>
                        <ContentText
                            lineHeight={isTablet ? '16px' : '18px'}
                            fontSize={isTablet ? '12px' : '14px'}
                            marginLeft={"6px"}>
                            (13488) 20, Pangyo-ro 289beon-gil, Bundang-gu, Seongnam-si, Gyeonggi-do, Republic of Korea
                        </ContentText>
                    </FlexBox>
                    <FlexBox
                        visible={!LanguageUtils.isKorean(language)}
                        marginTop={"10px"}
                        alignItems={"center"}>
                        <LabelText
                            fontSize={isTablet ? '12px' : '14px'}
                            lineHeight={isTablet ? '16px' : '18px'}>
                            Contact
                        </LabelText>
                        <ContentText
                            lineHeight={isTablet ? '16px' : '18px'}
                            fontSize={isTablet ? '12px' : '14px'}
                            marginLeft={"6px"}>
                            +82 10-2476-9700
                        </ContentText>
                    </FlexBox>
                </FlexBox>
            </FlexBox>
        </FlexBox>}
        {/*------------------ Mobile ------------------*/}
        {isTablet && <FlexBox width={"100%"}
                              flexDirection={"column"}>
            <FlexBox minWidth={"360px"}
                     width={"360px"}
                     margin={"0 auto"}
                     flexDirection={"column"}
                     paddingLeft={"10px"}
                     paddingRight={"10px"}
                     paddingTop={"40px"}
                     paddingBottom={"40px"}>
                <Image
                    alt={"footer-logo"}
                    width={"194px"}
                    style={{
                        cursor: "pointer"
                    }}
                    src={require("../../promotion-thirdworks-web/src/assets/footer-logo.svg").default}/>
                <Text
                    marginTop={"20px"}
                    fontSize={"12px"}
                    lineHeight={"12px"}
                    color={Colors.GRAY_400}>COPYRIGHT 2023 ⓒ THIRDWORKS ALL RIGHTS RESERVED </Text>

                <FlexBox
                    marginTop={"20px"}
                    alignItems={"center"}>
                    <PolicyText
                        lineHeight={isTablet ? '16px' : '18px'}
                        fontSize={isTablet ? '12px' : '14px'}
                        maxWidth={isTablet ? '180px' : '220px'}
                        width={"100px"}
                        onClick={() => {
                            window.open("https://www.thirdworks.co.kr/terms-of-service?lang=en")
                        }}>
                        Terms of Service
                    </PolicyText>
                    <PolicyText
                        lineHeight={isTablet ? '16px' : '18px'}
                        fontSize={isTablet ? '12px' : '14px'}
                        maxWidth={isTablet ? '180px' : '220px'}
                        marginLeft={"20px"}
                        onClick={() => {
                            window.open("https://www.thirdworks.co.kr/privacy-policy?lang=en")
                        }}>
                        Privacy Policy
                    </PolicyText>
                </FlexBox>
                <FlexBox
                    visible={LanguageUtils.isKorean(language)}
                    marginTop={"10px"}
                    alignItems={"center"}>
                    <PolicyText
                        lineHeight={isTablet ? '16px' : '18px'}
                        fontSize={isTablet ? '12px' : '14px'}
                        maxWidth={isTablet ? '180px' : '220px'}
                        width={"100px"}
                        onClick={() => {
                            window.open("/copyright-policy")
                        }}>
                        {t('copyright_policy')}
                    </PolicyText>
                    <PolicyText
                        lineHeight={isTablet ? '16px' : '18px'}
                        fontSize={isTablet ? '12px' : '14px'}
                        maxWidth={isTablet ? '180px' : '220px'}
                        marginLeft={"20px"}
                        onClick={() => {
                            window.open("/email-policy")
                        }}>
                        {t('deny_unauthorized_collection_of_email_addresses')}
                    </PolicyText>
                </FlexBox>
                <HorizontalDivider
                    marginTop={"20px"}
                    backgroundColor={"#E3E3E3"}/>
                <FlexBox
                    marginTop={"20px"}
                    alignItems={"center"}>
                    <LabelText
                        fontSize={isTablet ? '12px' : '14px'}
                        lineHeight={isTablet ? '16px' : '18px'}>
                        THIRD WORKS INC.
                    </LabelText>
                </FlexBox>
                <FlexBox
                    visible={LanguageUtils.isKorean(language)}
                    marginTop={"10px"}
                    alignItems={"center"}>
                    <LabelText
                        fontSize={isTablet ? '12px' : '14px'}
                        lineHeight={isTablet ? '16px' : '18px'}>
                        Representative
                    </LabelText>
                    <ContentText
                        lineHeight={isTablet ? '16px' : '18px'}
                        fontSize={isTablet ? '12px' : '14px'}
                        marginLeft={"6px"}>
                        Kim Minji
                    </ContentText>
                    <VerticalDivider backgroundColor={Colors.SUB_TXT2}
                                     height={"12px"}
                                     marginLeft={"10px"}
                                     marginRight={"10px"}/>
                    <LabelText
                        fontSize={isTablet ? '12px' : '14px'}
                        lineHeight={isTablet ? '16px' : '18px'}>
                        Email
                    </LabelText>
                    <ContentText
                        lineHeight={isTablet ? '16px' : '18px'}
                        fontSize={isTablet ? '12px' : '14px'}
                        marginLeft={"6px"}>
                        business@thirdworks.co.kr
                    </ContentText>
                </FlexBox>
                <FlexBox
                    visible={!LanguageUtils.isKorean(language)}
                    marginTop={"10px"}
                    alignItems={"center"}>
                    <LabelText
                        fontSize={isTablet ? '12px' : '14px'}
                        lineHeight={isTablet ? '16px' : '18px'}>
                        Representative
                    </LabelText>
                    <ContentText
                        lineHeight={isTablet ? '16px' : '18px'}
                        fontSize={isTablet ? '12px' : '14px'}
                        marginLeft={"6px"}>
                        Kim Minji
                    </ContentText>
                </FlexBox>
                <FlexBox
                    visible={!LanguageUtils.isKorean(language)}
                    marginTop={"10px"}
                    alignItems={"center"}>
                    <LabelText
                        fontSize={isTablet ? '12px' : '14px'}
                        lineHeight={isTablet ? '16px' : '18px'}>
                        Email
                    </LabelText>
                    <ContentText
                        lineHeight={isTablet ? '16px' : '18px'}
                        fontSize={isTablet ? '12px' : '14px'}
                        marginLeft={"6px"}>
                        business@thirdworks.co.kr
                    </ContentText>
                </FlexBox>
                <FlexBox
                    visible={LanguageUtils.isKorean(language)}
                    marginTop={"10px"}
                    alignItems={"center"}>
                    <LabelText
                        fontSize={isTablet ? '12px' : '14px'}
                        lineHeight={isTablet ? '16px' : '18px'}>
                        Business registration number
                    </LabelText>
                    <ContentText
                        lineHeight={isTablet ? '16px' : '18px'}
                        fontSize={isTablet ? '12px' : '14px'}
                        marginLeft={"6px"}>
                        784-87-02728
                    </ContentText>
                    <VerticalDivider backgroundColor={Colors.SUB_TXT2}
                                     height={"12px"}
                                     marginLeft={"10px"}
                                     marginRight={"10px"}/>
                    <LabelText
                        fontSize={isTablet ? '12px' : '14px'}
                        lineHeight={isTablet ? '16px' : '18px'}>
                        {t('cpo')}
                    </LabelText>
                    <ContentText
                        lineHeight={isTablet ? '16px' : '18px'}
                        fontSize={isTablet ? '12px' : '14px'}
                        marginLeft={"6px"}>
                        Kim Minji
                    </ContentText>
                </FlexBox>
                <FlexBox
                    visible={!LanguageUtils.isKorean(language)}
                    marginTop={"10px"}
                    alignItems={"center"}>
                    <LabelText
                        fontSize={isTablet ? '12px' : '14px'}
                        lineHeight={isTablet ? '16px' : '18px'}>
                        Business registration number
                    </LabelText>
                    <ContentText
                        lineHeight={isTablet ? '16px' : '18px'}
                        fontSize={isTablet ? '12px' : '14px'}
                        marginLeft={"6px"}>
                        784-87-02728
                    </ContentText>
                </FlexBox>
                <FlexBox
                    visible={!LanguageUtils.isKorean(language)}
                    marginTop={"10px"}
                    alignItems={"center"}>
                    <LabelText
                        fontSize={isTablet ? '12px' : '14px'}
                        lineHeight={isTablet ? '16px' : '18px'}>
                        {t('cpo')}
                    </LabelText>
                    <ContentText
                        lineHeight={isTablet ? '16px' : '18px'}
                        fontSize={isTablet ? '12px' : '14px'}
                        marginLeft={"6px"}>
                        Kim Minji
                    </ContentText>
                </FlexBox>
                <FlexBox
                    marginTop={"10px"}
                    alignItems={"center"}>
                    <LabelText
                        fontSize={isTablet ? '12px' : '14px'}
                        lineHeight={isTablet ? '16px' : '18px'}>
                        E-Commerce Registration Number
                    </LabelText>
                    <ContentText
                        lineHeight={isTablet ? '16px' : '18px'}
                        fontSize={isTablet ? '12px' : '14px'}
                        marginLeft={"6px"}>
                        Je2023-Sungnam BundangA-0171 Ho
                    </ContentText>
                </FlexBox>
                <FlexBox
                    marginTop={"10px"}
                    alignItems={"center"}>
                    <LabelText
                        fontSize={isTablet ? '12px' : '14px'}
                        lineHeight={isTablet ? '16px' : '18px'}>
                        Contact
                    </LabelText>
                    <ContentText
                        lineHeight={isTablet ? '16px' : '18px'}
                        fontSize={isTablet ? '12px' : '14px'}
                        marginLeft={"6px"}>
                        +82 10-2476-9700
                    </ContentText>
                </FlexBox>
                <FlexBox
                    marginTop={"10px"}
                    alignItems={"flex-start"}>
                    <LabelText
                        fontSize={isTablet ? '12px' : '14px'}
                        lineHeight={isTablet ? '16px' : '18px'}>
                        Address
                    </LabelText>
                    <ContentText
                        lineHeight={isTablet ? '16px' : '18px'}
                        fontSize={isTablet ? '12px' : '14px'}
                        marginLeft={"6px"}>
                        (13488) 20, Pangyo-ro 289beon-gil, Bundang-gu, Seongnam-si, Gyeonggi-do, Republic of Korea
                    </ContentText>
                </FlexBox>
            </FlexBox>
        </FlexBox>}
    </FlexBox>
}
export default CommonFooter;