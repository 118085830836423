import styled from "styled-components";
import CommonComponent from "./CommonComponent";

const FlexBox = styled(CommonComponent)`
`;

FlexBox.defaultProps = {
    position: "relative",
    display: "flex"
}

export default FlexBox;