import styled from "styled-components";
import CommonComponent, {CommonProps} from "./CommonComponent";

export interface TextProps extends CommonProps {
    reactive?: boolean
    whiteSpace?: "normal" | "nowrap" | "pre-wrap" | "pre-line"
    textOverflow?: "ellipsis" | "fade"
}

const Text = styled(CommonComponent)<TextProps>`
  width: ${({width}) => width || "fit-content"};
  text-align: ${({textAlign}) => textAlign || "left"};
  line-height: ${({lineHeight}) => lineHeight || "normal"};
  height: ${({height}) => height || "fit-content"};
  font-size: ${({fontSize}) => fontSize || "16px"};
  font-weight: ${({fontWeight}) => fontWeight || "normal"};
  white-space: ${({whiteSpace}) => whiteSpace || "pre-line"};
  text-overflow: ${({textOverflow}) => textOverflow || undefined};
`;

export default Text;