import styled from "styled-components";
import Text from "../Text";

/**
 * @author jaeho.lee104 on 2023. 08. 08..
 */
const NewP2 = styled(Text)`
  font-feature-settings: 'clig' off, 'liga';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

export default NewP2;