import React, {useState} from 'react';
import './App.css';
import CommonFooter from "./CommonFooter";
import FlexBox from "./styledcomponents/FlexBox";
import {Accordion} from "react-bootstrap";
import {Colors} from "./styledcomponents/Styles";
import useMobile from "./uses/UseMobile";
import NewH2 from "./styledcomponents/text/NewH2";
import AnugaPromotionRequestDialog from "./AnugaPromotionRequestDialog";

function App() {
    const isMobile = useMobile()
    const [isAnugaPromotionRequestDialog, setIsAnugaPromotionRequestDialog] = useState(false)
    const [inputEmail, setInputEmail] = useState("")
    window.onload = function () {
        const requestDialogButton = document.getElementById("request-dialog") as HTMLButtonElement
        requestDialogButton.onclick = () => {
            const inputEmail = document.getElementById("input-email") as HTMLInputElement
            handleRequestDialog(inputEmail.value)
        }

        const requestDialogButton2 = document.getElementById("request-dialog2") as HTMLButtonElement
        requestDialogButton2.onclick = () => {
            const inputEmail = document.getElementById("input-email") as HTMLInputElement
            handleRequestDialog(inputEmail.value)
        }
    }

    function handleRequestDialog(email: string) {
        setInputEmail(email)
        setIsAnugaPromotionRequestDialog(true)
    }

    return (
        <div className="App">
            <div>
                <FlexBox
                    backgroundColor={Colors.GRAY_0}
                    justifyContent={"center"}>
                    <FlexBox
                        backgroundColor={Colors.WHITE}
                        margin={isMobile ? "20px" : "80px"}
                        maxWidth={"1440px"}
                        boxShadow={"0px 2px 40px 0px rgba(0, 0, 0, 0.05)"}
                        borderRadius={"12px"}
                        padding={isMobile ? "20px" : "40px"}
                        flexDirection={"column"}
                        alignItems={"center"}
                        width={"100%"}>
                        <NewH2
                            color={Colors.BLACK}
                            marginTop={"20px"}
                            marginBottom={"20px"}>Q&A</NewH2>
                        <Accordion className="w-100 p-3" defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>1. Who can use THIRDWORKS ?</Accordion.Header>
                                <Accordion.Body>
                                    We can provide the market report for customs prices, guides and market share by
                                    competitors you need for
                                    exporting to south korea. And we can recommend Korean partner and buyer companies
                                    can match your
                                    business in the future
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>2. How much data is available in the dashboard ?</Accordion.Header>
                                <Accordion.Body>
                                    We provide imported food products from HS CODE 01 to 24.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>3. When is the data update cycle ?</Accordion.Header>
                                <Accordion.Body>
                                    All data is updated in the 1st week of every month.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>4. Where does trade and customs data come from ?</Accordion.Header>
                                <Accordion.Body>
                                    Trade data (monthly for products at the 6-digit HS code level) is provided by UN
                                    Comtrade, Korean
                                    customs offices, and the statistical office.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>
                                    5. What types of information can I get from the market intelligence
                                    ?</Accordion.Header>
                                <Accordion.Body>
                                    Our customers have been asking us about the current market trends in south korea and
                                    any similar
                                    products have been imported before and what would be the amount of it and what’s the
                                    customs price,
                                    customs procedure. We can provide you with all information based on customs data.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="5">
                                <Accordion.Header>6. What information is included in the market analysis report
                                    ?</Accordion.Header>
                                <Accordion.Body>
                                    The report’s contents are Korea Customs procedures and market trends, competitive
                                    company analysis
                                    according to the HS Code you required. We can provide you exact competitor names,
                                    their market share and
                                    contract patterns, what products are deal with and they are making the business with
                                    whom.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="6">
                                <Accordion.Header>7. Do you take market research or trade inquiries ?</Accordion.Header>
                                <Accordion.Body>
                                    Yes, We support customized market analysis reports including requesting quotes,
                                    negotiating prices, and
                                    preparing customs documents.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="7">
                                <Accordion.Header>8. What is the process for the partner matching service
                                    ?</Accordion.Header>
                                <Accordion.Body>
                                    In buyer matching service, we can provide you 3 buyers information that interested
                                    in your
                                    products. <br/>For checking the company
                                    credit, we are checking
                                    the 3 different
                                    standards.
                                    <ul>
                                        <li>- Whether the buyer have an experience for the import or export based on
                                            Customs data
                                        </li>
                                        <li>- Does membership is registered in public company like trade association and
                                            import organization
                                        </li>
                                        <li>- Do they have appropriate market share in South Korea.</li>
                                    </ul>
                                    If you need some assistance, you can send us the your company details and your
                                    product information by
                                    email. <br/>we will introduce you the 3 buyer companies details that match your
                                    business in 2 weeks ~ 2
                                    months.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="8">
                                <Accordion.Header>9. How can I use the service once the promotion period ends
                                    ?</Accordion.Header>
                                <Accordion.Body>
                                    You can subscribe our premium plan in our website(<a style={{fontWeight: 500}}
                                                                                         target={"_blank"}
                                                                                         href={"https://www.thirdworks.co.kr?lang=en"}>https://www.thirdworks.co.kr/</a>)
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="9">
                                <Accordion.Header>10. How do I cancel the promotion plan ?</Accordion.Header>
                                <Accordion.Body>
                                    If you are on promotions service, you don’t need to cancel by yourself. Your
                                    promotion account will be
                                    closed within 30days.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                    </FlexBox>
                </FlexBox>
            </div>
            <div>
                <CommonFooter/>
            </div>
            <AnugaPromotionRequestDialog
                dialogProps={{
                    isOpen: isAnugaPromotionRequestDialog,
                    email: inputEmail,
                    onClose: (positive) => {
                        setIsAnugaPromotionRequestDialog(false)
                    }
                }}/>
        </div>
    );
}

export default App;
