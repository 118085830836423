/**
 * @author jaeho.lee104 on 2023. 04. 07..
 */
export class RegexUtils {

    static checkEmail(email: string): boolean {
        return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
    }

    static checkOnlyNumber(number: string): boolean {
        return /^\d+$/.test(number)
    }

    static checkPassword(password: string): boolean {
        return /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/.test(password)
    }

    static checkPasswordWithoutLength(password: string): boolean {
        return /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/.test(password)
    }

}