import styled from "styled-components";
import Text from "../Text";
import {Sizes} from "../Styles";

/**
 * @author jaeho.lee104 on 2023. 08. 08..
 */
const NewH2 = styled(Text)`
  text-align: center;
  font-feature-settings: 'clig' off, 'liga';
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
  @media screen and ${Sizes.MEDIA_QUERY_MOBILE} {
    font-size: 24px;
  }
`;

export default NewH2;