import {useMediaQuery} from "react-responsive";
import {Sizes} from "../styledcomponents/Styles";

/**
 * @author jaeho.lee104 on 2023. 04. 08..
 */
const useTablet = () => {
    return useMediaQuery({
        query: Sizes.MEDIA_QUERY_TABLET,
    });
}
export default useTablet