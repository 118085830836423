import styled from "styled-components";
import {CommonProps} from "./CommonComponent";
import {Colors} from "./Styles";

export interface InputProps extends CommonProps {
    borderOnFocus?: string;
    hintColor?: string;
    hintFontWeight?: number | "normal";
    caretColor?: string;
    error?: boolean;
}

const Input = styled.input<InputProps>`
  box-shadow: ${({boxShadow}) => boxShadow || undefined};
  opacity: ${({opacity}) => opacity || undefined};
  flex-grow: ${({flexGrow}) => flexGrow || undefined};
  position: ${({position}) => position || undefined};
  border-radius: ${({borderRadius}) => borderRadius || "12px"};
  float: ${({float}) => float || undefined};
  margin-left: ${({marginLeft}) => marginLeft || undefined};
  margin-right: ${({marginRight}) => marginRight || undefined};
  margin-bottom: ${({marginBottom}) => marginBottom || undefined};
  margin-top: ${({marginTop}) => marginTop || undefined};
  padding-left: ${({paddingLeft}) => paddingLeft || "16px"};
  padding-right: ${({paddingRight}) => paddingRight || "16px"};
  padding-bottom: ${({paddingBottom}) => paddingBottom || "14px"};
  padding-top: ${({paddingTop}) => paddingTop || "14px"};
  width: ${({width}) => width || undefined};
  height: ${({height}) => height || "52px"};
  min-width: ${({minWidth}) => minWidth || undefined};
  min-height: ${({minHeight}) => minHeight || undefined};
  max-width: ${({maxWidth}) => maxWidth || undefined};
  max-height: ${({maxHeight}) => maxHeight || undefined};
  border: ${({border, error}) => (error && `1px solid ${Colors.INPUT_ERROR}`) || border || `1px solid ${Colors.GRAY_4}`};
  border-right: ${({borderRight}) => borderRight || undefined};
  border-left: ${({borderLeft}) => borderLeft || undefined};
  border-top: ${({borderTop}) => borderTop || undefined};
  border-bottom: ${({borderBottom}) => borderBottom || undefined};
  color: ${({color}) => color || Colors.GRAY_8};
  font-size: ${({fontSize}) => fontSize || "16px"};
  font-weight: ${({fontWeight}) => fontWeight || 500};
  display: ${({display}) => display || "block"};
  align-content: ${({alignContent}) => alignContent || undefined};
  align-items: ${({alignItems}) => alignItems || undefined};
  flex-direction: ${({flexDirection}) => flexDirection || undefined};
  flex-wrap: ${({flexWrap}) => flexWrap || undefined};
  justify-content: ${({justifyContent}) => justifyContent || undefined};
  align-self: ${({alignSelf}) => alignSelf || undefined};
  background-color: ${({backgroundColor}) => backgroundColor || Colors.WHITE};
  background: ${({background}) => background || Colors.TRANSPARENT};
  cursor: ${({cursor}) => cursor || undefined};
  right: ${({right}) => right || undefined};
  left: ${({left}) => left || undefined};
  top: ${({top}) => top || undefined};
  bottom: ${({bottom}) => bottom || undefined};
  z-index: ${({zIndex}) => zIndex || undefined};
  line-height: ${({lineHeight}) => lineHeight || undefined};
  caret-color: ${({caretColor}) => caretColor || "#4E5968"};

  &:hover {
    border: 1px solid ${Colors.CTA_HOVER};

    &:disabled {
      border: 1px solid ${Colors.GRAY_4};
    }
  }

  &:focus {
    outline: none;
    border: ${({borderOnFocus, error}) =>
            (error && `2px solid ${Colors.INPUT_ERROR}`) || borderOnFocus || `2px solid ${Colors.MAIN}`};
  }

  &::placeholder {
    color: ${({hintColor}) => hintColor || Colors.GRAY_5};
    font-weight: ${({hintFontWeight}) => hintFontWeight || 500};
  }

  &:disabled {
    color: ${Colors.GRAY_5};
    border: 1px solid ${Colors.GRAY_4};
    background: ${Colors.GRAY_1};
  }
`;

export default Input;