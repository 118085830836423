/**
 * @author jaeho.lee104 on 2023. 09. 19..
 */
import React, {useEffect, useState} from "react";
import useMobile from "../../promotion-thirdworks-web/src/uses/UseMobile";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {Dialog} from "@mui/material";
import FlexBox from "../../promotion-thirdworks-web/src/styledcomponents/FlexBox";
import Image from "../../promotion-thirdworks-web/src/styledcomponents/Image";
import NewH3 from "../../promotion-thirdworks-web/src/styledcomponents/text/NewH3";
import {Colors} from "./styledcomponents/Styles";
import NewH4 from "../../promotion-thirdworks-web/src/styledcomponents/text/NewH4";
import NewP2 from "../../promotion-thirdworks-web/src/styledcomponents/text/NewP2";
import NewP4 from "../../promotion-thirdworks-web/src/styledcomponents/text/NewP4";
import HorizontalDivider from "../../promotion-thirdworks-web/src/styledcomponents/HorizontalDivider";
import Input from "../../promotion-thirdworks-web/src/styledcomponents/Input";
import TextArea from "antd/lib/input/TextArea";
import {StringConstants} from "./StringConstants";
import {KeyboardUtils} from "./KeyboardUtils";
import {Checkbox} from "antd";
import {CheckboxChangeEvent} from "antd/es/checkbox";
import emailjs from "@emailjs/browser";
import NewButton from "../../promotion-thirdworks-web/src/styledcomponents/NewButton";

export interface AnugaPromotionRequestDialogProps {
    isOpen: boolean,
    email: string,
    onClose: (positive: boolean) => void
}

const AnugaPromotionRequestDialog: React.FC<{
    dialogProps: AnugaPromotionRequestDialogProps
}> = ({dialogProps}) => {

    const [isOpen, setIsOpen] = useState(false);
    const isMobile = useMobile()
    const [name, setName] = useState("")
    const [company, setCompany] = useState("")
    const [email, setEmail] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [message, setMessage] = useState("")
    const [termsOfServiceAgreeChecked, setTermsOfServiceAgreeChecked] = useState(false);

    const theme = createTheme({
        components: {
            MuiDialog: {
                styleOverrides: {
                    paper: {
                        minWidth: isMobile ? "90%" : "600px",
                        borderRadius: "16px"
                    }
                }
            }
        },
        palette: {
            background: {
                paper: '#fff',
            },
            text: {
                primary: '#000',
                secondary: '#46505A',
            },
            action: {
                active: '#001E3C',
            },
        },
    });

    useEffect(() => {
        setIsOpen(dialogProps.isOpen);
    }, [dialogProps.isOpen]);

    useEffect(() => {
        setEmail(dialogProps.email)
    }, [dialogProps.email])

    const handleOk = () => {
        if (!isOpen) {
            return;
        }
        const params = {
            company_name: company,
            user_name: name,
            phone_number: phoneNumber,
            email: email,
            message: message
        }
        if (!company) {
            alert('Please enter the company name.')
            return
        }
        if (!name) {
            alert('Please enter your name.')
            return
        }
        if (!phoneNumber) {
            alert('Please enter your phone number.')
            return
        }
        if (!email) {
            alert('Please enter your email address.')
            return
        }
        if (!message) {
            alert('Please enter your inquiry.')
            return;
        }
        if (!termsOfServiceAgreeChecked) {
            alert('Please agree to the privacy policy.')
            return;
        }
        emailjs.send('service_476mxkw', 'template_v7222p4', params, 'aWur-HWDDnLCPvv6l')
            .then((result) => {
                alert('Submitted!\nYour dedicated manager will be in touch with you shortly.')
                dialogProps.onClose(true);
            }, (error) => {
                alert('Your promotion request failed. Please try again later.')
            });
    };

    const handleCancel = () => {
        if (!isOpen) {
            return;
        }
        dialogProps.onClose(false);
    };
    return <>
        {!isMobile && <ThemeProvider theme={theme}>
            <Dialog
                open={isOpen}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        handleOk();
                    }
                }}>
                <FlexBox
                    width={"100%"}
                    padding={"16px 32px"}
                    flexDirection={"column"}>
                    <FlexBox
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        marginTop={"16px"}>
                        <NewH3
                            color={Colors.GRAY_9}>
                            {'Apply for Promotion'} 🎉
                        </NewH3>
                        <Image
                            cursor={"pointer"}
                            width={"24px"}
                            height={"24px"}
                            zIndex={10}
                            src={require("../../promotion-thirdworks-web/src/assets/images/ic-dialog-close.svg").default}
                            onClick={handleCancel}
                            alt="ic-dialog-close"/>
                    </FlexBox>
                    <FlexBox
                        width={"100%"}
                        backgroundColor={Colors.GRAY_0}
                        border={`1px solid ${Colors.GRAY_2}`}
                        borderRadius={"12px"}
                        flexDirection={"column"}
                        marginTop={"16px"}
                        padding={"12px 16px"}>
                        <FlexBox
                            alignItems={"center"}>
                            <Image
                                width={"16px"}
                                height={"16px"}
                                src={require("../../promotion-thirdworks-web/src/assets/images/ic-check-2.svg").default}
                                alt="ic-check"/>
                            <NewP2
                                marginLeft={"8px"}
                                color={Colors.GRAY_7}>
                                Free 1 month data subscription
                            </NewP2>
                        </FlexBox>
                        <FlexBox
                            alignItems={"center"}
                            marginTop={"12px"}>
                            <Image
                                width={"16px"}
                                height={"16px"}
                                src={require("../../promotion-thirdworks-web/src/assets/images/ic-check-2.svg").default}
                                alt="ic-check"/>
                            <NewP2
                                marginLeft={"8px"}
                                color={Colors.GRAY_7}>
                                Free 3 multiple quotation
                            </NewP2>
                        </FlexBox>
                        <FlexBox
                            alignItems={"center"}
                            marginTop={"12px"}>
                            <Image
                                width={"16px"}
                                height={"16px"}
                                src={require("../../promotion-thirdworks-web/src/assets/images/ic-check-2.svg").default}
                                alt="ic-check"/>
                            <NewP2
                                marginLeft={"8px"}
                                color={Colors.GRAY_7}>
                                1 Target Market Analysis Report
                            </NewP2>
                        </FlexBox>
                        <FlexBox
                            alignItems={"center"}
                            marginTop={"12px"}>
                            <Image
                                width={"16px"}
                                height={"16px"}
                                src={require("../../promotion-thirdworks-web/src/assets/images/ic-check-2.svg").default}
                                alt="ic-check"/>
                            <NewP2
                                marginLeft={"8px"}
                                color={Colors.GRAY_7}>
                                Guide for Korea Import Procedure
                            </NewP2>
                        </FlexBox>
                    </FlexBox>
                    <FlexBox
                        marginTop={"40px"}
                        justifyContent={"space-between"}
                        alignItems={"center"}>
                        <NewH4>
                            {'Information'}
                        </NewH4>
                        <NewP4
                            color={Colors.GRAY_6}>
                            <span style={{
                                color: Colors.DESTRUCTIVE_HOVER
                            }}>*</span> {'Please fill in the following information.'}
                        </NewP4>
                    </FlexBox>
                    <HorizontalDivider
                        marginTop={"16px"}
                        backgroundColor={Colors.GRAY_3}/>
                    <FlexBox
                        marginTop={"24px"}
                        alignItems={"center"}>
                        <FlexBox
                            width={"100%"}
                            flexDirection={"column"}
                            justifyContent={"flex-start"}>
                            <NewP2
                                color={Colors.GRAY_7}>
                                {'Name'}<span style={{color: Colors.DESTRUCTIVE_HOVER}}>*</span>
                            </NewP2>
                            <Input
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value)
                                }}
                                hintFontWeight={400}
                                fontWeight={400}
                                color={Colors.GRAY_8}
                                hintColor={Colors.GRAY_5}
                                marginTop={"8px"}/>
                        </FlexBox>
                        <FlexBox
                            width={"100%"}
                            marginLeft={"16px"}
                            flexDirection={"column"}
                            justifyContent={"flex-start"}>
                            <NewP2
                                color={Colors.GRAY_7}>
                                {'Company'}<span style={{color: Colors.DESTRUCTIVE_HOVER}}>*</span>
                            </NewP2>
                            <Input
                                value={company}
                                onChange={(e) => {
                                    setCompany(e.target.value)
                                }}
                                hintFontWeight={400}
                                fontWeight={400}
                                color={Colors.GRAY_8}
                                hintColor={Colors.GRAY_5}
                                marginTop={"8px"}/>
                        </FlexBox>
                    </FlexBox>
                    <FlexBox
                        marginTop={"24px"}
                        alignItems={"center"}>
                        <FlexBox
                            width={"100%"}
                            flexDirection={"column"}
                            justifyContent={"flex-start"}>
                            <NewP2
                                color={Colors.GRAY_7}>
                                E-Mail<span style={{color: Colors.DESTRUCTIVE_HOVER}}>*</span>
                            </NewP2>
                            <Input
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value)
                                }}
                                hintFontWeight={400}
                                fontWeight={400}
                                color={Colors.GRAY_8}
                                hintColor={Colors.GRAY_5}
                                marginTop={"8px"}/>
                        </FlexBox>
                        <FlexBox
                            width={"100%"}
                            marginLeft={"16px"}
                            flexDirection={"column"}
                            justifyContent={"flex-start"}>
                            <NewP2
                                color={Colors.GRAY_7}>
                                {'Phone'}<span style={{color: Colors.DESTRUCTIVE_HOVER}}>*</span>
                            </NewP2>
                            <Input
                                value={phoneNumber}
                                onChange={(e) => {
                                    setPhoneNumber(KeyboardUtils.clearNumber(e.target.value))
                                }}
                                hintFontWeight={400}
                                fontWeight={400}
                                color={Colors.GRAY_8}
                                hintColor={Colors.GRAY_5}
                                marginTop={"8px"}/>
                        </FlexBox>
                    </FlexBox>
                    <NewP2
                        marginTop={"24px"}
                        color={Colors.GRAY_7}>
                        {'Promotions of interest'}<span style={{color: Colors.MAIN}}>({'optional'})</span>
                    </NewP2>
                    <TextArea
                        value={message}
                        onChange={(e) => {
                            setMessage(e.target.value)
                        }}
                        showCount
                        maxLength={400}
                        style={{
                            height: 190,
                            marginTop: "8px",
                            resize: 'none',
                            padding: "8px",
                        }}
                        placeholder={`※ ${'Example'}\n\n1. ${'We would like to request a sample report.'}\n2. ${'We would like to request a sample data.'}\n3. ${'We would like to request a export trading in Korea.'}`}
                    />
                    <FlexBox
                        marginTop={"24px"}
                        alignItems={"center"}>
                        <Checkbox
                            checked={termsOfServiceAgreeChecked}
                            onChange={(e: CheckboxChangeEvent) => {
                                setTermsOfServiceAgreeChecked(e.target.checked);
                            }}/>
                        <NewP2
                            fontWeight={400}
                            marginLeft={"8px"}>
                            [{'required'}]{StringConstants.SPACE}
                            {'I agree to Thirdworks’s '}
                            <a style={{
                                fontWeight: "500",
                                cursor: "pointer",
                                borderBottom: `1px solid ${Colors.MAIN}`,
                                color: Colors.MAIN
                            }}
                               onClick={() => {
                                   window.open("https://www.thirdworks.co.kr/privacy-policy")
                               }}>{'Terms of Service and Privacy Policy.'}</a></NewP2>
                    </FlexBox>
                    <FlexBox
                        alignItems={"center"}
                        marginTop={"16px"}
                        padding={"16px 0px"}>
                        <NewButton
                            colorType={2}
                            height={"52px"}
                            width={"100%"}
                            fontSize={"16px"}
                            fontWeight={500}
                            borderRadius={"12px"}
                            onClick={handleCancel}>
                            {'Cancel'}
                        </NewButton>
                        <NewButton
                            colorType={0}
                            height={"52px"}
                            width={"100%"}
                            marginLeft={"16px"}
                            fontSize={"16px"}
                            fontWeight={700}
                            onClick={handleOk}
                            borderRadius={"12px"}>
                            {'Apply'}
                        </NewButton>
                    </FlexBox>
                </FlexBox>
            </Dialog>
        </ThemeProvider>}
        {isMobile && <ThemeProvider theme={theme}>
            <Dialog
                open={isOpen}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        handleOk();
                    }
                }}>
                <FlexBox
                    width={"100%"}
                    padding={"16px 32px"}
                    flexDirection={"column"}>
                    <FlexBox
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        marginTop={"16px"}>
                        <NewH3
                            color={Colors.GRAY_9}>
                            프로모션 신청하기 🎉
                        </NewH3>
                        <Image
                            cursor={"pointer"}
                            width={"24px"}
                            height={"24px"}
                            zIndex={10}
                            src={require("../../promotion-thirdworks-web/src/assets/images/ic-dialog-close.svg").default}
                            onClick={handleCancel}
                            alt="ic-dialog-close"/>
                    </FlexBox>
                    <FlexBox
                        width={"100%"}
                        backgroundColor={Colors.GRAY_0}
                        border={`1px solid ${Colors.GRAY_2}`}
                        borderRadius={"12px"}
                        flexDirection={"column"}
                        marginTop={"16px"}
                        padding={"12px 16px"}>
                        <FlexBox
                            alignItems={"center"}>
                            <Image
                                width={"16px"}
                                height={"16px"}
                                src={require("../../promotion-thirdworks-web/src/assets/images/ic-check-2.svg").default}
                                alt="ic-check"/>
                            <NewP2
                                marginLeft={"8px"}
                                color={Colors.GRAY_7}>
                                Free 1 month data subscription
                            </NewP2>
                        </FlexBox>
                        <FlexBox
                            alignItems={"center"}
                            marginTop={"12px"}>
                            <Image
                                width={"16px"}
                                height={"16px"}
                                src={require("../../promotion-thirdworks-web/src/assets/images/ic-check-2.svg").default}
                                alt="ic-check"/>
                            <NewP2
                                marginLeft={"8px"}
                                color={Colors.GRAY_7}>
                                Free 3 multiple quotation
                            </NewP2>
                        </FlexBox>
                        <FlexBox
                            alignItems={"center"}
                            marginTop={"12px"}>
                            <Image
                                width={"16px"}
                                height={"16px"}
                                src={require("../../promotion-thirdworks-web/src/assets/images/ic-check-2.svg").default}
                                alt="ic-check"/>
                            <NewP2
                                marginLeft={"8px"}
                                color={Colors.GRAY_7}>
                                1 Target Market Analysis Report
                            </NewP2>
                        </FlexBox>
                        <FlexBox
                            alignItems={"center"}
                            marginTop={"12px"}>
                            <Image
                                width={"16px"}
                                height={"16px"}
                                src={require("../../promotion-thirdworks-web/src/assets/images/ic-check-2.svg").default}
                                alt="ic-check"/>
                            <NewP2
                                marginLeft={"8px"}
                                color={Colors.GRAY_7}>
                                Guide for Korea Import Procedure
                            </NewP2>
                        </FlexBox>
                    </FlexBox>
                    <FlexBox
                        marginTop={"40px"}
                        justifyContent={"space-between"}
                        alignItems={"center"}>
                        <NewH4>
                            기본 정보 입력
                        </NewH4>
                        <NewP4
                            color={Colors.GRAY_6}>
                            <span style={{
                                color: Colors.DESTRUCTIVE_HOVER
                            }}>*</span> 표시된 부분은 필수 입력 항목입니다.
                        </NewP4>
                    </FlexBox>
                    <HorizontalDivider
                        marginTop={"16px"}
                        backgroundColor={Colors.GRAY_3}/>
                    <FlexBox
                        width={"100%"}
                        marginTop={"24px"}
                        flexDirection={"column"}
                        justifyContent={"flex-start"}>
                        <NewP2
                            color={Colors.GRAY_7}>
                            {'Name'}<span style={{color: Colors.DESTRUCTIVE_HOVER}}>*</span>
                        </NewP2>
                        <Input
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value)
                            }}
                            hintFontWeight={400}
                            fontWeight={400}
                            color={Colors.GRAY_8}
                            hintColor={Colors.GRAY_5}
                            marginTop={"8px"}/>
                    </FlexBox>
                    <FlexBox
                        marginTop={"12px"}
                        width={"100%"}
                        flexDirection={"column"}
                        justifyContent={"flex-start"}>
                        <NewP2
                            color={Colors.GRAY_7}>
                            {'Company'}<span style={{color: Colors.DESTRUCTIVE_HOVER}}>*</span>
                        </NewP2>
                        <Input
                            value={company}
                            onChange={(e) => {
                                setCompany(e.target.value)
                            }}
                            hintFontWeight={400}
                            fontWeight={400}
                            color={Colors.GRAY_8}
                            hintColor={Colors.GRAY_5}
                            marginTop={"8px"}/>
                    </FlexBox>
                    <FlexBox
                        marginTop={"12px"}
                        width={"100%"}
                        flexDirection={"column"}
                        justifyContent={"flex-start"}>
                        <NewP2
                            color={Colors.GRAY_7}>
                            E-Mail<span style={{color: Colors.DESTRUCTIVE_HOVER}}>*</span>
                        </NewP2>
                        <Input
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value)
                            }}
                            hintFontWeight={400}
                            fontWeight={400}
                            color={Colors.GRAY_8}
                            hintColor={Colors.GRAY_5}
                            marginTop={"8px"}/>
                    </FlexBox>
                    <FlexBox
                        marginTop={"12px"}
                        width={"100%"}
                        flexDirection={"column"}
                        justifyContent={"flex-start"}>
                        <NewP2
                            color={Colors.GRAY_7}>
                            {'Phone'}<span style={{color: Colors.DESTRUCTIVE_HOVER}}>*</span>
                        </NewP2>
                        <Input
                            value={phoneNumber}
                            onChange={(e) => {
                                setPhoneNumber(KeyboardUtils.clearNumber(e.target.value))
                            }}
                            hintFontWeight={400}
                            fontWeight={400}
                            color={Colors.GRAY_8}
                            hintColor={Colors.GRAY_5}
                            marginTop={"8px"}/>
                    </FlexBox>
                    <NewP2
                        marginTop={"12px"}
                        color={Colors.GRAY_7}>
                        관심있는 프로모션<span style={{color: Colors.MAIN}}>(선택)</span>
                    </NewP2>
                    <TextArea
                        value={message}
                        onChange={(e) => {
                            setMessage(e.target.value)
                        }}
                        showCount
                        maxLength={400}
                        style={{
                            height: 190,
                            marginTop: "8px",
                            resize: 'none',
                            padding: "8px",
                        }}
                        placeholder={`※ ${'Example'}\n\n1. ${'We would like to request a sample report.'}\n2. ${'We would like to request a sample data.'}\n3. ${'We would like to request a export trading in Korea.'}`}
                    />
                    <FlexBox
                        marginTop={"24px"}
                        alignItems={"center"}>
                        <Checkbox
                            checked={termsOfServiceAgreeChecked}
                            onChange={(e: CheckboxChangeEvent) => {
                                setTermsOfServiceAgreeChecked(e.target.checked);
                            }}/>
                        <NewP2
                            fontWeight={400}
                            marginLeft={"8px"}>
                            [{'required'}]{StringConstants.SPACE}
                            {'I agree to Thirdworks’s '}
                            <a style={{
                                fontWeight: "500",
                                cursor: "pointer",
                                borderBottom: `1px solid ${Colors.MAIN}`,
                                color: Colors.MAIN
                            }}
                               onClick={() => {
                                   window.open("https://www.thirdworks.co.kr/privacy-policy")
                               }}>{'Terms of Service and Privacy Policy.'}</a></NewP2>
                    </FlexBox>
                    <FlexBox
                        alignItems={"center"}
                        marginTop={"16px"}
                        padding={"16px 0px"}>
                        <NewButton
                            colorType={2}
                            height={"52px"}
                            width={"100%"}
                            fontSize={"16px"}
                            fontWeight={500}
                            borderRadius={"12px"}
                            onClick={handleCancel}>
                            {'Cancel'}
                        </NewButton>
                        <NewButton
                            colorType={0}
                            height={"52px"}
                            width={"100%"}
                            marginLeft={"16px"}
                            fontSize={"16px"}
                            fontWeight={700}
                            onClick={handleOk}
                            borderRadius={"12px"}>
                            {'Apply'}
                        </NewButton>
                    </FlexBox>
                </FlexBox>
            </Dialog>
        </ThemeProvider>}
    </>
}
export default AnugaPromotionRequestDialog;